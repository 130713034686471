import { inject, Injectable } from '@angular/core';
import { WINDOW } from '@libs/utils/shared';

export enum GoalNameEnum {
  // vk app
  mainLinkDiscrodNow = 'mainLinkDiscordNow',
  mainLinkDiscrodLater = 'mainLinkDiscordLater',
  mainCreateProfile = 'mainCreateProfile',
  mainCreateCharacter = 'mainCreateCharacter',
  mainCreateCommunity = 'mainCreateCommunity',
  mainCreateGuild = 'mainCreateGuild',
  // yandex metrika app
  mainCreateProfileYm = 'mainCreateProfile',
  mainLoginYm = 'mainLogin',
  mainLoginSendYm = 'mainLoginSend',
  mainLoginCancelYm = 'mainLoginCancel',
  mainLinkDiscrodNowYm = 'mainLinkDiscordNow',
  mainLinkDiscrodLaterYm = 'mainLinkDiscordLater',
  mainCreateProfileCompleteYm = 'mainCreateProfile',
  mainCreateProfileCancelYm = 'mainCreateProfileCancel',
  mainCreateCharacterYm = 'mainCreateCharacter',
  mainCreateCharacterCompleteYm = 'mainCreateCharacter',
  mainCreateCharacterCancelYm = 'mainCreateCharacterCancel',
  mainCreateCommunityYm = 'mainCreateCommunity',
  mainCreateCommunityCompleteYm = 'mainCreateCommunityComplete',
  mainCreateGuildYm = 'mainCreateGuild',
  mainCreateGuildCompleteYm = 'mainCreateGuildComplete',
  mainCreateGuildCancelYm = 'mainCreateGuildCancel',
  // vk landing
  landingStartRegTop = 'landingStartRegTop',
  landingStartRegMiddle = 'landingStartRegMiddle',
  landingStartRegBottom = 'landingStartRegBottom',
  landingSendReg = 'landingSendReg',

  // yandex landing
  landingStartRegTopYm = 'landingStartRegTop',
  landingSendRegTopYm = 'landingSendRegTop',
  landingCancelRegTopYm = 'landingCancelRegTop',
  landingStartRegMiddleYm = 'landingStartRegMiddle',
  landingSendRegMiddleYm = 'landingSendRegMiddle',
  landingCancelRegMiddleYm = 'landingCancelRegMiddle',
  landingStartRegBottomYm = 'landingStartRegBottom',
  landingSendRegBottomYm = 'landingSendRegBottom',
  landingCancelRegBottomYm = 'landingCancelRegBottom',
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private readonly window: Window = inject(WINDOW);
  private readonly counterId = 3423287;
  private readonly yandexId = 90670448;

  init() {
    const tmr = this.window['_tmr'] || (this.window['_tmr'] = []);
    tmr.push({ id: this.counterId.toString(), type: 'pageView', start: new Date().getTime() });

    const id = 'tmr-code';

    if (document.getElementById(id)) {
      return;
    }

    const counterScriptNode = document.createElement('script');
    counterScriptNode.type = 'text/javascript';
    counterScriptNode.async = true;
    counterScriptNode.id = id;
    counterScriptNode.src = 'https://top-fwz1.mail.ru/js/code.js';

    const f = function () {
      const headNode = document.getElementsByTagName('head')[0];
      headNode.appendChild(counterScriptNode);
    };

    // eslint-disable-next-line eqeqeq
    if (this.window['opera'] == '[object Opera]') {
      document.addEventListener('DOMContentLoaded', f, false);
    } else {
      f();
    }
  }

  trackEventByGoalName(goalName: string): void {
    // vk tracking
    const trackEventObj = { type: 'reachGoal', id: this.counterId, goal: goalName };
    const tmr = this.window['_tmr'] || (this.window['_tmr'] = []);

    tmr.push(trackEventObj);
  }

  trackEventByGoalNameForYandex(goalName: string): void {
    // yandex tracking
    this.window['ym'](this.yandexId, 'reachGoal', goalName);
  }
}
