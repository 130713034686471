import {CommonModule} from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, Provider, SkipSelf} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ClanhallSDKApiModule, Configuration} from '@libs/data-access/clanhall-sdk';
import {ConsoleLoggerProvider, LOGGER_PROVIDER_TOKEN, LoggerTypeEnum} from '@libs/utils/logger';
import {ENV_TOKEN, KEY_TO_CAMEL_CASE_INTERCEPTOR_PROVIDER, WINDOW_PROVIDER} from '@libs/utils/shared';
import {createTranslateLoader, DEFAULT_LANGUAGE} from '@libs/utils/translation';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {Store} from '@ngxs/store';

import {environment} from '../../../environments';
import {AppConfigState} from '../store';
import {createNGXSModules} from '../store/create-store-modules';
import {appInit} from './core-module-fn';

const INTERCEPTORS: Provider[] = [KEY_TO_CAMEL_CASE_INTERCEPTOR_PROVIDER];

@NgModule({ declarations: [], imports: [CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            defaultLanguage: DEFAULT_LANGUAGE,
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader(['assets/i18n/']),
                deps: [HttpClient],
            },
        }),
        ClanhallSDKApiModule.forRoot(() => new Configuration({ basePath: environment.sdkBaseUrl })),
        ...createNGXSModules(environment, [AppConfigState])], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded.');
        }
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                WINDOW_PROVIDER,
                {provide: ENV_TOKEN, useValue: environment},
                ...INTERCEPTORS,
                {
                    provide: APP_INITIALIZER,
                    useFactory: appInit,
                    deps: [Store],
                    multi: true,
                },
                {
                    provide: LOGGER_PROVIDER_TOKEN,
                    useFactory: () => {
                        switch (environment.loggerType) {
                            case LoggerTypeEnum.Console:
                            default:
                                return new ConsoleLoggerProvider();
                        }
                    },
                },
            ],
        };
    }
}
