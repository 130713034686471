import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

import { LoggerMessage, LoggerMessageTypeEnum, LoggerService } from '@libs/utils/logger';

import { IDocModalData } from './doc-modal-data.interface';

@Component({
  selector: 'ui-doc-modal',
  templateUrl: './doc-modal.component.html',
  styleUrls: ['./doc-modal.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [],
})
export class DocModalComponent implements OnInit {
  desc: string;
  body: string;

  constructor(
    config: DynamicDialogConfig,
    private readonly dialogRef: DynamicDialogRef,
    private readonly logger: LoggerService,
  ) {
    config.header = config.data?.document.name || '';
    this.checkDocFields(config.data?.document);
    this.desc = config.data?.document.desc || '';
    this.body = config.data?.document.body || '';
  }

  ngOnInit(): void {}

  private checkDocFields(doc: IDocModalData['document']) {
    const errorPattern = (fieldName: string) => `Empty doc field: ${fieldName}`;

    if (!doc.name) {
      this.logger.log(
        new LoggerMessage(errorPattern('name'), {
          type: LoggerMessageTypeEnum.InternalError,
          rawInfo: doc,
        }),
      );
    }

    if (!doc.desc) {
      this.logger.log(
        new LoggerMessage(errorPattern('desc'), {
          type: LoggerMessageTypeEnum.InternalWarn,
          rawInfo: doc,
        }),
      );
    }

    if (!doc.body) {
      this.logger.log(
        new LoggerMessage(errorPattern('body'), {
          type: LoggerMessageTypeEnum.InternalCriticalError,
          rawInfo: doc,
        }),
      );
    }
  }
}
