import { HttpClient } from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {forkJoin, map} from 'rxjs';

import {LanguagesEnum} from './languages.enum';

export function createTranslateLoader(prefixes: string[] = ['assets/i18n/']) {
    return (http: HttpClient) => {
        return {
            getTranslation: (lang: string) =>
                forkJoin(
                    prefixes.map(prefix => {
                        return new TranslateHttpLoader(http, prefix, '.json').getTranslation(lang);
                    }),
                ).pipe(
                    map(translations => {
                        return translations.reduce((acc, t) => {
                            return {...acc, ...t};
                        }, {});
                    }),
                ),
        };
    };
}

export const DEFAULT_LANGUAGE = LanguagesEnum.Russian;
