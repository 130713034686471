<div class="image"></div>
<div class="reg-form">
  <div class="inner-container">
    <div [innerHTML]="'LIBS.MODALS.REGISTRATION_MODAL.TITLE' | translate" class="title"></div>
    <div
      class="sub-title"
      [innerHTML]="
        !(registered$ | async)
          ? ('LIBS.MODALS.REGISTRATION_MODAL.SUB_TITLE' | translate)
          : ('LIBS.MODALS.REGISTRATION_MODAL.SUB_TITLE-SUCCESS' | translate)
      "
    ></div>
    <div class="auth-methods">
      <!--            <ng-container *ngFor="let method of authMethods">-->
      <!--                <ui-button (click)="goRegistration(method)" [text]="method.name" color="primary" stretch="true"-->
      <!--                           type="stroked"></ui-button>-->
      <!--            </ng-container>-->
      <app-register-by-email
        *ngIf="!(registered$ | async)"
        (onPushEmail)="registerByEmail($event)"
      ></app-register-by-email>

      <ng-container *ngIf="authMethods.length < 1">
        <span
          [innerHTML]="'LIBS.MODALS.REGISTRATION_MODAL.ACTUAL_AUTH_METHODS_EMPTY' | translate"
        ></span>
      </ng-container>
    </div>
    <footer *ngIf="!(registered$ | async)" (click)="translationDocHandler($event)">
      <span [innerHTML]="'LIBS.MODALS.REGISTRATION_MODAL.FOOTER' | translate | sanitizeHtml"></span>
    </footer>
  </div>
</div>
